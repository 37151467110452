import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestBaseService } from 'src/app/shared/services/rest-base.service';
import { environment } from 'src/environments/environment';
import { PagedResultDto } from 'src/app/shared/models/paging-sorting';
import { SubjectListFilterDto } from '../models/SubjectListFilterDto.model';
import { SubjectListItemDto } from '../models/SubjectListItemDto.model';
import { SubjectResponseDto } from '../models/SubjectResponseDto.model';
import { SubjectUpdateDto } from '../models/SubjectUpdateDto.model';
import { SubjectCreateDto } from '../models/SubjectCreateDto.model';

@Injectable({
  providedIn: 'root',
})
export class SubjectService extends RestBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getFilteredList(
    filterDto: SubjectListFilterDto
  ): Observable<PagedResultDto<SubjectListItemDto>> {
    return this.GetFilteredAsync<PagedResultDto<SubjectListItemDto>>(
      filterDto,
      environment.api.subjectApiUrl
    );
  }

  getById(id: number): Observable<SubjectResponseDto> {
    return this.GetByIdAsync<SubjectResponseDto>(
      id,
      environment.api.subjectApiUrl
    );
  }

  create(createDto: SubjectCreateDto): Observable<any> {
    return this.CreateAsync<any>(
      createDto,
      environment.api.subjectApiUrl
    );
  }

  update(id: number, updateDto: SubjectUpdateDto) {
    return this.UpdateAsync<any>(
      id,
      updateDto,
      environment.api.subjectApiUrl
    );
  }

  deleteById(id: number) {
    return this.DeleteAsync(
      id,
      environment.api.subjectApiUrl
    );
  }
}
