<div class="table-top no-padding">
  <div class="table-top-left max-full">
    <div class="title">Subjekto paieška</div>
  </div>
  <div class="clear"></div>
</div>
<form [formGroup]="searchForm">
  <div class="block bordered">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label>Pavadinimas:</label>
          <input type="text" placeholder="Įveskite pavadinimo fragmentą.."
            formControlName="name" />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <app-clsf-dropdown
            [label]="'Teisinė forma:'"
            [options]="subjectLegalFormsOptions"
            formControlName="legalForm"
          >
          </app-clsf-dropdown>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label>Kodas:</label>
          <input type="text" placeholder="Įveskite kodą.."
            formControlName="identityNumber" />
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <app-clsf-dropdown
            [label]="'Būsena:'"
            [options]="subjectStatusOptions"
            formControlName="status"
          >
          </app-clsf-dropdown>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label>Nestruktūrizuotas adresas:</label>
          <input type="text" placeholder="Įveskite adreso fragmentą.."
            formControlName="unstructuredAddress" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12"></div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12"></div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12"></div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <a href="" class="btn btn-light btn-small btn-full" (click)="onSearchClick($event)">
            Ieškoti
          </a>
        </div>
      </div>
    </div>
  </div>
</form>