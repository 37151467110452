import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { SubjectListFilterDto } from '../models/SubjectListFilterDto.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Classifiers } from '../../classifiers/classifiers.enum';
import { subjectLegalFormOptionsSelect } from 'src/app/shared/enums/subject-legal-form';
import { subjectStatusOptionsSelect } from 'src/app/shared/enums/subject-status.enum';

@Component({
  selector: 'app-subject-search',
  standalone: false,
  templateUrl: './subject-search.component.html'
})
export class SubjectSearchComponent implements OnInit {
  private fb = inject(FormBuilder);

  @Input() searchDto: SubjectListFilterDto = {};
  @Output() onSearch = new EventEmitter<SubjectListFilterDto>();

  searchForm!: FormGroup;

  subjectStatusOptions = subjectStatusOptionsSelect;
  subjectLegalFormsOptions = subjectLegalFormOptionsSelect;
  clsfs: typeof Classifiers = Classifiers;

  get f() { return this.searchForm.controls; }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.searchForm = this.fb.group({
      name: [this.searchDto.name || null, Validators.nullValidator],
      legalForm: [this.searchDto.legalForm || null, Validators.nullValidator],
      identityNumber: [this.searchDto.identityNumber || null, Validators.nullValidator],
      status: [this.searchDto.status || null, Validators.nullValidator],    
      unstructuredAddress: [this.searchDto.unstructuredAddress || null, Validators.nullValidator]
    });
  }

  onSearchClick(event: Event) {
    event.preventDefault();
    this.onSearch.emit(this.searchForm.value);
  }
}