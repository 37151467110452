import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { UserAuthorizationService } from '../../user/user-authorization.service';
import { PagedResultDto } from 'src/app/shared/models/paging-sorting';
import { SubjectListItemDto } from '../models/SubjectListItemDto.model';
import { SubjectStatus } from 'src/app/shared/enums/subject-status.enum';

@Component({
  selector: 'app-subject-list',
  standalone: false,
  templateUrl: './subject-list.component.html',
})
export class SubjectListComponent {
  readonly userAuthService = inject(UserAuthorizationService);

  @Input() rows: PagedResultDto<SubjectListItemDto> = {};
  @Input() isLoading: boolean | null = null;
  @Input() showDeleteBtn = true;
  @Output() onView = new EventEmitter<SubjectListItemDto>();
  @Output() onDelete = new EventEmitter<SubjectListItemDto>();

  subjectStatuses = SubjectStatus;

  viewClickHandler(row: SubjectListItemDto) {
    this.onView.emit(row);
  }

  deleteClickHandler(row: SubjectListItemDto) {
    this.onDelete.emit(row);
  }
}
