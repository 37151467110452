<app-header-wrapper>
  <app-primary-menu-header></app-primary-menu-header>
</app-header-wrapper>
<app-content-wrapper>
  <app-subject-search
    (onSearch)="subjectSearchHandler($event)"
    [searchDto]="filter"
  ></app-subject-search>
  <div class="table-top">
    <div class="table-top-left">
      <div class="title">Subjektai</div>
      <app-page-size-dropdown
        [(ngModel)]="list.maxResultCount"
      ></app-page-size-dropdown>
    </div>
    <div class="table-top-right">
      <div class="buttons">
        <a class="btn btn-small btn-min160"
          href="subject/create"
          *ngIf="userAuthService.canCreateSubject">
          Naujas subjektas
        </a>
      </div>
    </div>
    <div class="clear"></div>
  </div>
  <app-subject-list
    [isLoading]="list.isLoading$ | async"
    [rows]="rows"
    [showDeleteBtn]="true"
    (onView)="viewSubject($event)"
    (onDelete)="deleteSubject($event)"
  ></app-subject-list>
  <app-table-paging
    [totalCount]="rows.totalCount"
    [(page)]="list.page"
    [maxResultCount]="list.maxResultCount"
  ></app-table-paging>
</app-content-wrapper>
