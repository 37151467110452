<div class="table-responsive" *ngIf="userAuthService.canViewSubjectList">
  <table class="table-stripes">
    <thead>
      <tr>
        <th>Tipas</th>
        <th>Teisinė forma</th>
        <th>FA / JA Pavadinimas</th>
        <th>Kodas</th>
        <th>Adresas</th>
        <th>Būsena</th>
        <th>Rizika</th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="!isLoading && rows.totalCount && rows.totalCount > 0">
      <tr *ngFor="let row of rows.items">   
        <td>{{ row.type | subjectTypeLabel }}</td>
        <td>{{ row.legalForm | subjectLegalFormLabel }}</td>
        <td>
          <span class="link" (click)="viewClickHandler(row)" >{{ row.name || '-' }}</span>
        </td>
        <td>{{ row.identityNumber || '-' }}</td>
        <td>{{ row.unstructuredAddress }}</td>
        <td>{{ row.status | subjectStatusLabel }}</td>
        <td><app-risk-group-label [subjectId]="row.id" [status]="row.riskEvaluation" [useModal]="true"></app-risk-group-label></td>
        <td class="text-right">
          <div class="btn-group btn-group-justified">
            <button
              class="btn btn-gray mx-2"
              (click)="viewClickHandler(row)" *ngIf="userAuthService.canViewSubjectDetails"
            >
              Žiūrėti
            </button>
            <button [disabled]="row?.status === subjectStatuses.Canceled" class="btn btn-gray" (click)="deleteClickHandler(row)" *ngIf="userAuthService.canDeleteSubjects && showDeleteBtn">
              Panaikinti
            </button>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!isLoading && !rows.totalCount || rows.totalCount === 0">
      <tr>
        <td colspan="12" class="text-center">Subjektų nėra</td>
      </tr>
      </tbody>
      <tbody *ngIf="isLoading">
      <tr>
        <td colspan="12">
          <app-spinner></app-spinner>
        </td>
      </tr>
      </tbody>
  </table>
</div>
