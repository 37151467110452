import { Component, OnInit, inject } from '@angular/core';
import { SubjectListItemDto } from './models/SubjectListItemDto.model';
import { PagedResultDto } from 'src/app/shared/models/paging-sorting';
import { SubjectListFilterDto } from './models/SubjectListFilterDto.model';
import { ListService } from 'src/app/shared/services/list.service';
import { UserAuthorizationService } from '../user/user-authorization.service';
import { Router } from '@angular/router';
import { SubjectService } from './services/subject.service';
import { SubjectStatus } from 'src/app/shared/enums/subject-status.enum';

@Component({
  selector: 'app-subject',
  standalone: false,
  templateUrl: './subject.component.html',
  styleUrl: './subject.component.less',
  providers: [ListService]
})
export class SubjectComponent implements OnInit {
  private service = inject(SubjectService);
  private router = inject(Router);
  public list = inject(ListService<SubjectListFilterDto>);
  public userAuthService = inject(UserAuthorizationService);

  rows: PagedResultDto<SubjectListItemDto> = {};

  filter: SubjectListFilterDto = {
    status: SubjectStatus.Active
  };
  
  ngOnInit() {
    this.getSubjectList();
  }

  getSubjectList() {
    const subjectStreamCreator = (query: any) =>
      this.service.getFilteredList({
        ...query,
        ...this.filter,
      });

    this.list.hookToQuery(subjectStreamCreator).subscribe((response) => {
      this.rows = response;
    });
  }

  subjectSearchHandler(filter: SubjectListFilterDto) {
    this.filter = filter;
    this.list.get();
  }

  viewSubject(row: SubjectListItemDto) {
    this.router.navigate([`subject/view/${row.id}`]);
  }

  deleteSubject(row: SubjectListItemDto) {
    this.service.deleteById(row.id).subscribe(() => {
      this.list.get();
    });
  }
}